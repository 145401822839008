import React from "react"

import defaultAvatar from "../images/logo.png"

const defaultStoreName = '蓝迪智能裁缝'
export const rootPath = "uc"
export const keySeparator = "$"
export const hostDomain = 'landidz.com'

//开发调试数据
export const devUserData = {
	nickName: '闪光少女',
	realName: '李晓峰',
	accountType: '超级管理员',
	storeName: defaultStoreName,
	avatar: defaultAvatar
}


const devFirstShop = {
	shopName: '熊小明定制',
	domain: 'xxxxm',
	ownerName: '熊小明',
	subShops: [
		{
			area: ['四川', '南充', '营山县'],
			address: '文林路31号',
			primaryPhone: '028-12344321'
		}
	]
}

export {defaultAvatar, defaultStoreName, devFirstShop}

