import React, { useEffect, useState } from "react";
import { get } from "../server";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import loading from "../libs/loading";
import { PhoneOutlined } from "@ant-design/icons";
import "./index.less";
import kf from "../../images/kf.png";
import { Divider } from "antd";

const App = loading(() => import("../../index/app"));
const NotFound = loading(() => import("../../index/errors/404"));
const Home = loading(() => import("../../index/home"));

const empty = () => () => "empty function";


export const AppContext = React.createContext({
  userInfo: {},
  userInfoBusy: false,
  refreshUser: empty(),
  setUserInfo: empty(),
  getUserInfo: empty(),
  shoppingCartCount: 0,
  setShoppingCartCount: empty(),
  setPrimaryColor: empty(),
  primaryColor: "",
  buyAgainInfo: {},
  setBuyAgainInfo: empty(),
  cartInfo: {},
  setCartInfo: empty(),
});

export const appContext = (Component) => (props) =>
  (
    <AppContext.Consumer>
      {(context) => <Component {...props} context={context} />}
    </AppContext.Consumer>
  );

const defaultSite = {
  title:'私人定制彰显品味',
  detail:'细心服务,精湛工艺,为您打造专属的时尚精品!\n' +
    '个性化设计,舒适面料,让您感受到无与伦比的穿着体验!\n' +
    '打造专属您的时尚标签,私人定制,让您的品味与个性同在!\n' +
    '品质之选,风格之韵,私人定制,尽显您的优雅与品味!\n' +
    '为您量身打造的私人定制服装,让您的每一个细节都散发自信与魅力!\n' +
    '让您成为众人瞩目的焦点!\n',
  slogan:'您身边的形象顾问，为您打造独一无二的时尚风格！',
  phoneNo:'4001609268',
  name:'自己的店名'
}

export const ContextBox = (props) => {
  const [userInfo, $userInfo] = useState({});
  const [buyAgainInfo, $buyAgainInfo] = useState({});
  const [userInfoBusy, $userInfoBusy] = useState(false);
  const [shoppingCartCount, $shoppingCartCount] = useState(0);
  const [primaryColor, $primaryColor] = useState("");
  const [kfShow, $kfShow] = useState(false);
  const [cartInfo, $cartInfo] = useState({});
  const [modelDiscount, $modelDiscount] = useState(undefined);
  const [websiteInfo, $websiteInfo] = useState(undefined);


  const getUserInfo = () => {
    $userInfoBusy(true);
    get("/user/info", {}, () => {})
      .then((data) => {
        $userInfo(Object.assign({}, data));
        //保存头像到本地
        localStorage.setItem(`${data.userName}_avatar`, data.avatar);
        localStorage.setItem(`${data.phoneNo}_avatar`, data.avatar);
      })
      .catch((e) => {})
      .finally(() => $userInfoBusy(false));
    get("/paperModelOrder/modelDiscount").then((resData) => {
      $modelDiscount(resData);
    });
    get("/website/info").then((resData) => {
      console.info(resData,'-----------------------')
      if(resData){
        $websiteInfo({
          ...defaultSite,
          ...resData,
        });
      }else{
        console.info(defaultSite,'----------------------- defaultSite -----------------')
        $websiteInfo(defaultSite);
      }
    });
  };

  const contextValue = {
    userInfo,
    setUserInfo: $userInfo,
    buyAgainInfo,
    setBuyAgainInfo: $buyAgainInfo,
    userInfoBusy,
    getUserInfo,
    refreshUser: getUserInfo,
    shoppingCartCount,
    setShoppingCartCount: $shoppingCartCount,
    primaryColor,
    setPrimaryColor: $primaryColor,
    cartInfo,
    setCartInfo: $cartInfo,
    modelDiscount,
    websiteInfo,
  };

  useEffect(getUserInfo, [(userInfo || {}).name]);

  return (
    <>
      <AppContext.Provider value={contextValue}>
        <BrowserRouter basename={"/"} forceRefresh={false}>
          <Switch>
            <Route path={"/"} exact={true} component={Home} />
            <Route path={"/home"} exact={true} component={Home} />
            <Route exact={true} component={App} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
};
