/*认证异常*/
export default class AuthenticationError extends Error {

	static NAME = 'AuthenticationError'

	constructor(message, code) {
		super(message)
		this.name = AuthenticationError.NAME
		this.code = code
	}

}