import React from "react"
import ReactDOM from "react-dom"
import "index.less"
import zhCN from 'antd/lib/locale-provider/zh_CN'
import {LoadingOutlined} from '@ant-design/icons'
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';
import {ConfigProvider, message, Spin} from 'antd'
import Prelude from "./index/prelude"
import {userSetting} from "./shared/libs/antd-theme"
import {ContextBox} from "./shared/context/app-context"
import {SOCKET_HOST} from "./shared/project-config"
import {saveToken} from "./shared/libs/common"

const root = document.getElementById("root")
window.socket = io.connect(`${SOCKET_HOST}`, {reconnect: true})

const globalConfig = {
  locale: locale || zhCN
  // renderEmpty: () => <Empty/>,
}

message.config({
  duration: 2,
  top: 10,
  getContainer: () => {
    const appContainer = document.getElementById('app-container-right')
    const root = appContainer == null ? document.body : appContainer
    return root
  }
})

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>
Spin.setDefaultIndicator(antIcon)
userSetting(true)

saveToken()

//用于测试下载
//download("http://api.landidz.com/order/download/2199481ab21c11eb95260242c0a83003")

const onWindowLoad = () => {
  if (document.readyState === 'complete') {
    setTimeout(() => {
      ReactDOM.render(
        <ConfigProvider {...globalConfig}>
          <ContextBox/>
        </ConfigProvider>
        , root)
    }, 200)
  } else {
    ReactDOM.render(
      <Prelude/>
      , root)
  }
}

document.onreadystatechange = onWindowLoad

