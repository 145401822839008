/*认证异常*/
export default class AuthorizationError extends Error {

	static NAME = 'AuthorizationError'

	constructor(message, code) {
		super(message)
		this.name = AuthorizationError.NAME
		this.code = code
	}

}