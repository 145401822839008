//判断是否是有效会员
import {tokenKey} from "../project-config";

const checkIsMember = (member) => {
  if (!member) return false
  if (member.expired) return false
  if (member.remainFreeNum < 1) return false
  return true
}

const checkIsMemberByUser = ({member}) => {
  return checkIsMember(member)
}

const getQueryParam = (url, ignoreBlank) => {
  const us = url.split('?')
  const param = {}
  if (us && us.length > 1) {
    const ps = us[1]
    const pair = ps.split('&')
    if (pair) {
      pair.forEach(p => {
        const kv = p.split('=')
        if (kv.length > 1) {
          if (ignoreBlank) {
            if (kv[1]) {
              param[kv[0]] = kv[1]
            }
          } else {
            param[kv[0]] = kv[1]
          }
        }
      })
    }
  }
  return param
}

const saveToken = () => {
  const href = window.location.href
  const param = getQueryParam(href, true)
  const token = param.token
  if (token) {
    localStorage.setItem(tokenKey, token)
  }
}


const imageBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


export {checkIsMember, checkIsMemberByUser, getQueryParam, saveToken, imageBase64}