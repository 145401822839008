/*业务异常*/
export default class BusinessError extends Error {

	static NAME = 'BusinessError'

	constructor(message, code) {
		super(message)
		this.name = BusinessError.NAME
		this.code = code
	}

}