import React from "react"
import {Button} from 'antd'
import {rootPath, defaultAvatar} from "./init"

//设置不同环境
//https://www.jianshu.com/p/0c85db98169c

export const env = process.env.NODE_ENV
export const DEV = "development"

console.info(process.env,process)

console.log('%c************************************************', 'color: yellow;')
console.log('%c************************************************', 'color: red;')
console.log(`%cenv=${env}`, 'color: green;')
console.info(`%cHOST=${process.env.REACT_APP_EVN_HOST}`, 'color: green;')
console.log(`%cSOCKET=${process.env.REACT_APP_EVN_SOCKET}`, 'color: green;')
console.log('%c************************************************', 'color: red;')
console.log('%c************************************************', 'color: yellow;')
//高德地图apiKey
export const gdMapApiKey = '8d64769c7e4f30f99c4d8cb6fc47da63'

//API接口地址
export const HOST = process.env.REACT_APP_EVN_HOST
console.log(`%cSOCKET=${HOST}`, 'color: green;')
console.log(`%cEVN=${env}`, 'color: green;')
//export const HOST = 'https://api.landidz.com'
export const SERVER_HOST = HOST
export const SOCKET_HOST = process.env.REACT_APP_EVN_SOCKET

export const defaultRouter = `/${rootPath}/profile`

//校验正则
export const regex_mobile = /^(1\d{2})\d{8}$/
//邮箱正则
export const regex_email = /^.+@[\da-zA-Z]{1,20}\.[a-z]+$/
//身份证号正则
export const regex_id_card = /^(\d{15}|\d{17}[\dxX])$/

//系统中涉及的文件类型
export const fileType = {
  //博克CAD文件
  BOKE: 'BOKE',
  //不可编辑版型文件
  PLT: 'PLT',
  XLS: 'XLS',
  IMAGE: 'IMAGE'
}

export const pageSetting = {
  showSizeChanger: true,
  showQuickJumper: <Button size={'small'}>跳转</Button>,
  showTotal: (total, range) => (
    <div>
      共 <span className={'line-stress'}>{total}</span> 条,
      当前显示第 <span className={'line-stress'}>{range[0]}</span>-<span className={'line-stress'}>{range[1]}</span> 条
    </div>
  )
}

export const avatarPath = (url) => {
  if (!url) return defaultAvatar
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }
  return `${HOST}/${url}`
}

//默认的按钮尺寸
export const defaultSize = 'normal'

//排序类型
export const orderType = {
  ascend: 'ASC',
  descend: 'DESC'
}

export const tokenKey = "ldToken"

export const sizeParam = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
  'a1', 'b1', 'c1', 'd1', 'e1', 'f1', 'g1', 'h1', 'i1', 'j1', 'k1', 'l1', 'm1', 'n1', 'o1', 'p1', 'q1', 'r1', 's1', 't1', 'u1', 'v1', 'w1', 'x1', 'y1', 'z1',
  'a2', 'b2', 'c2', 'd2', 'e2', 'f2', 'g2', 'h2', 'i2', 'j2', 'k2', 'l2', 'm2', 'n2', 'o2', 'p2', 'q2', 'r2', 's2', 't2', 'u2', 'v2', 'w2', 'x2', 'y2', 'z2']
