import darkThemeSingle from "antd/dist/dark-theme"
import defaultTheme from "antd/dist/default-theme"
import {themeKeys} from "themeVars"
import {useState} from "react"
import {message} from "antd"

const themeKey = "zr.theme.ld.portal"
const themeLoginBgKey = "zr.theme.login.background.ld.portal"

const createTheme = (style, primaryColor) => {
  const theme = {}
  const antdTheme = style === 'dark' ? darkThemeSingle : defaultTheme

  themeKeys.forEach(k => {
    if (antdTheme[k])
      theme[`@${k}`] = antdTheme[k]
  })
  theme['@primary-color'] = primaryColor
  return theme
}

const changeTheme = (s, p, silent) => {
  const theme = createTheme(s, p)
  const hide = silent ? function () {
  } : message.loading('正在切换主题', 0);
  setTimeout(() => {
    document.body.setAttribute("data-theme", s)
    window.less
      .modifyVars(theme)
      .then(() => {
        console.info('切换成功')
      })
      .catch(e => {
        console.info('切换失败')
      }).finally(hide)
  }, 800)
}

const useTheme = () => {
  const [themeName, $themeName] = useState('default')
  const [primaryColor, $primaryColor] = useState('#1890ff')

  const refreshTheme = (s, p) => {
    $themeName(s)
    changeTheme(s || themeName, p || primaryColor)
    localStorage.setItem(themeKey, JSON.stringify({theme: s || themeName, primaryColor: p || primaryColor}))
  }

  const setTheme = s => {
    refreshTheme(s, null)
  }
  const setPrimaryColor = c => {
    $primaryColor(c)
    refreshTheme(null, c)
  }

  const toggleTheme = () => {
    const s = themeName === 'dark' ? 'default' : 'dark'
    document.body.setAttribute("data-theme", s)
    $themeName(s)
    refreshTheme(s, null)
  }

  const setBackground = (bg) => {
    localStorage.setItem(themeLoginBgKey, JSON.stringify(bg))
  }

  const getBackground = () => {
    const json = localStorage.getItem(themeLoginBgKey)
    return JSON.parse(json)
  }

  const getTheme = () => localStorage.getItem(themeKey)

  return {
    themeName,
    getTheme,
    setTheme,
    setPrimaryColor,
    primaryColor,
    toggleTheme,
    setBackground,
    getBackground
  }
}

const userSetting = (silent) => {
  const json = localStorage.getItem(themeKey)
  const {theme, primaryColor} = json && JSON.parse(json) || {}
  document.body.setAttribute("data-theme", theme || 'default')
  changeTheme(theme, primaryColor, silent)
}

export default useTheme
export {userSetting}


