import React from 'react'
import Loadable from 'react-loadable'
import Pace from "./pace"
import Prelude from "../../index/prelude"
// 按需加载组件
export default function loading(comp) {
	return Loadable({
		loader: comp,
		loading: (props) => {
			Pace.start()
			if (props.error) {
				return <div className={'loading-box'}>
					<div color={'loading-error'}>加载错误,请刷新!</div>
				</div>
			} else if (props.timedOut) {
				return <div className={'loading-box'}>
					加载超时,请刷新!
				</div>
			} else if (props.pastDelay) {
				// return <div className={'loading-box'}>
				//   <Spin tip="加载中" size={"large"}/>
				// </div>
				return <Prelude className={'small'}/>
			} else {
				Pace.stop()
				return null
			}
		},
		timeout: 10000,
		delay: 300
	})
}