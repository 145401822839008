import React from "react"
import "./index.less"
import circle from "../../images/svg/color-circle.svg"

const Prelude = ({className}) => {
	return <div className={'prelude'}>
		<div className={className}>
			<img src={circle} alt={''}/>
		</div>
	</div>
}

export default Prelude