/*超时异常*/
export default class TimeOutError extends Error {

	static NAME = 'TimeOutError'

	constructor(message, code) {
		super(message)
		this.name = TimeOutError.NAME
		this.code = code
	}

}