//实现类似中介者模式
//主要用于无界面组件调用时与界面互动
//例如:post/get等接口调用结果时需要反应到界面上
//接口调用抛出异常,如未登录异常,此时需要弹出登录窗口
//tag:store 用于存储回调注册
const store = {}

/**
 * @param name 用于注册回调的名称(可理解为事件名称)
 * @param fn 回调
 * */
const connect = (name, fn) => {
  store[name] = fn
}

/**
 * 事件源发起时调用对应的回调
 * @param name 回调名称(可理解为事件名称)
 * @param value 用于传递给回调方法的参数,具体参数类型由回调函数指定
 * */
const dispatch = (name, value) => {
  const fn = store[name]
  if (fn) {
    fn(value)
  }
}

export {connect, dispatch}