/*服务器内部错误*/
export default class InternalError extends Error {

	static NAME = 'InternalError'

	constructor(message, code) {
		super(message)
		this.name = InternalError.NAME
		this.code = code
	}

}